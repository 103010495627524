import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

//helper functions imports
import { TransactionData } from '../../helpers/interface';
import { getSvgByCategory } from '../../utils/getSvgByCaregory';
import { checkTransaction } from '../../utils/transactionFunc';
import {
    EventProperites,
    MixPanelCTA,
    MixPanelEvents,
    handlEvents,
} from '../../lib/mixPanel';
import {
    getFormattedDate,
    getRefundStatusBasisStatusCode,
    getStatusBasisStatusCode,
} from '../../utils';

//css imports
import styles from './transactioncard.module.scss';

const TransactionCard = ({ transaction }: { transaction: TransactionData }) => {
    const [trans, setTrans] = useState<TransactionData>();
    const router = useRouter();

    useEffect(() => {
        setTrans({ ...transaction, loading: false });
    }, [transaction]);

    const navigateToDetailPage = (transaction: TransactionData) => {
        handlEvents(
            MixPanelEvents.billzy_order_transaction,
            EventProperites.transcationDetail,
            EventProperites.recentTranscation,
            MixPanelCTA.click,
            'Proceed',
            undefined,
            undefined,
            transaction
        );

        router.push({
            pathname: '/v2/transactionDetail',
            query: {
                bckUrl: 'true',
                goBack: 'true',
                transaction: JSON.stringify(transaction),
            },
        });
    };

    const checkRefundTransactions = (data: TransactionData): boolean => {
        if (data?.refund_status_code_id) return true;
        return false;
    };

    const checkAndPay = (trans: TransactionData): boolean => {
        return (
            !trans?.refund_status_code_id &&
            trans?.status_code_id >= 299 &&
            trans?.flow_type === 'BBPS' &&
            trans?.is_partial_payment === true
        );
    };

    const checkAndRetry = (trans: TransactionData): boolean => {
        return (
            !trans?.refund_status_code_id &&
            [125, 225]?.indexOf(trans?.status_code_id) >= 0
        );
    };

    const checkAndPayAndRetry = async (e, type, o_id) => {
        e.stopPropagation();
        if (trans?.order_id === o_id) setTrans({ ...trans, loading: true });
        checkTransaction(type, o_id, router, true, () =>
            setTrans({ ...trans, loading: false })
        );
    };

    return (
        <div
            className={styles?.transctionItem}
            onClick={() => navigateToDetailPage(trans)}
        >
            <div className={styles?.transctionIcon}>
                {getSvgByCategory(trans?.biller_category)}
            </div>
            <div className="transction-detail">
                <div className={styles?.transctionTitle}>
                    {checkRefundTransactions(trans) ? 'Refund by ' : 'Paid to '}
                    {trans?.biller_name || ''}
                </div>
                <div style={{ display: 'flex', width: '130%' }}>
                    <span className={styles?.transctionDate}>
                        {getFormattedDate(
                            checkRefundTransactions(trans)
                                ? trans?.refundedat
                                : trans?.created
                        )}
                    </span>
                    <span
                        className={`transction-status-${
                            checkRefundTransactions(trans)
                                ? getRefundStatusBasisStatusCode(
                                      trans?.refund_status_code_id
                                  )?.replace(' ', '-')
                                : getStatusBasisStatusCode(
                                      trans?.status_code_id
                                  )
                        }`}
                    >
                        {checkRefundTransactions(trans)
                            ? getRefundStatusBasisStatusCode(
                                  trans?.refund_status_code_id
                              )
                            : getStatusBasisStatusCode(trans?.status_code_id)}
                    </span>
                </div>
            </div>
            <div className={styles?.transctionLeftItem}>
                <div className="transction-amount">
                    ₹
                    {!trans?.payable_amount
                        ? trans?.amount?.toLocaleString('en-IN')
                        : trans?.payable_amount?.toLocaleString('en-IN')}
                </div>
                {checkAndPay(trans) ? (
                    trans?.loading ? (
                        <div className="loader-small"></div>
                    ) : trans?.biller_category ? (
                        <p
                            className="transaction-detail-payagain"
                            onClick={(e) =>
                                checkAndPayAndRetry(
                                    e,
                                    'pay_balance',
                                    trans?.order_id
                                )
                            }
                        >
                            Check and Pay Again
                        </p>
                    ) : null
                ) : checkAndRetry(trans) ? (
                    trans?.loading ? (
                        <div className="loader-small"></div>
                    ) : trans?.biller_category ? (
                        <p
                            className="transaction-detail-payagain"
                            onClick={(e) => {
                                checkAndPayAndRetry(
                                    e,
                                    'retry_transaction',
                                    trans?.order_id
                                );
                            }}
                        >
                            Check and Retry
                        </p>
                    ) : null
                ) : null}
            </div>
        </div>
    );
};
export default TransactionCard;
